import expandableList from './expandableList'

export default {
  expandableList,
  confirm_modal: {
    cancel: 'Cancel',
  },
  cropper_modal: {
    upload_image: 'Upload image',
    image_requirement: 'Image requirement %{width} x %{height} px',
    change_image: 'Change image',
    cancel: 'Cancel',
    crop_and_upload: 'Crop and upload',
    preview: 'Preview',
  },
  no_search_results_found: {
    title: 'No results found',
    body: 'Try searching for something else',
  },
  search_input: {
    label: 'Search',
    clear: 'Clear',
  },
  side_nav: {
    other: 'Other',
  },
  text_expander: {
    show_more: 'Show %{count} more %{noun}',
  },
  update_banners: {
    replace: {
      title: 'This page is getting a new look and feel!',
      subtitle: 'Upcoming updates to Thanx include improvements to this page.',
    },
    beta: {
      title: 'This page is in beta',
      subtitle: 'You’ve got early access to an upcoming release.',
    },
    update: {
      title: 'Updates ahead',
      subtitle:
        'This page will be replaced by a different feature as part of upcoming improvements.',
    },
    delete: {
      title: 'Updates ahead',
      subtitle: 'This page will be removed as part of upcoming improvements.',
    },
    button: 'Learn about these updates',
    extra_subtitles: {
      july_23:
        'This report will be removed as part of upcoming improvements in July 2023.',
    },
  },
}
