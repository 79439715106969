import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import NoTestImage from 'assets/images/campaign-center/builder/img-no_test.png'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React from 'react'
import { push } from 'react-router-redux'
import { abTestingHelpUrl } from 'utilities/urlUtils'

const t = buildTranslate('thanx_campaigns.report.v3.test')

type Props = {
  state: string
}

const NoTest: React.FC<Props> = ({ state }) => {
  const dispatch = useDispatch()
  const onSetupNew = () => dispatch(push('/thanx_campaigns'))
  const translate =
    state === 'complete' ? 'no_test_completed_title' : 'no_test_title'
  return (
    <>
      <Text.Header4 className="mb-m">{t(translate)}</Text.Header4>
      <div className="width-100 mb-m">
        <img
          className="width-100 height-auto"
          src={NoTestImage}
          alt="no-test"
        />
      </div>
      <Text.BodyText3 color="grey70" tag="div">
        {t('no_test_description')}
      </Text.BodyText3>
      <Text.BodyText3 className="mt-m" color="grey70" tag="div">
        {t('no_test_multivariant_description')}
      </Text.BodyText3>
      <div className="d-flex justify-content-end mt-xl">
        <a
          href={abTestingHelpUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="no-underline"
        >
          <Button kind="secondary" className="mr-s">
            {t('learn_button')}
          </Button>
        </a>
        <Button kind="primary" onClick={onSetupNew}>
          {t('new_automated')}
        </Button>
      </div>
    </>
  )
}
export default NoTest
