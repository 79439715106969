import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { PointsMultiplier } from 'api/points'
import palette from 'constants/palette'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { dateRangeText, formatFactor } from './helpers'

type Props = {
  pointsMultiplier: PointsMultiplier
  pointsExperience: PointsExperience
}

const t = buildTranslate('points.configuration.multipliers')

function PreviewBadge(props: Props) {
  const { pointsMultiplier, pointsExperience } = props
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()

  function dateRange() {
    const dateString = dateRangeText(
      pointsMultiplier.date_range_starts_on,
      pointsMultiplier.date_range_ends_on
    )

    return dateString.charAt(0).toUpperCase() + dateString.slice(1)
  }

  return (
    <div
      className={`card mt-xs d-inline-flex flex-column p-s ${css({
        width: '400px',
        borderColor: palette.grey30,
      })}`}
    >
      <div className="mb-s">
        <div
          className={`d-inline-block text-uppercase py-xxs px-s ${css({
            border: `2px solid ${merchant?.primary_color || undefined}`,
            borderRadius: '20px',
          })}`}
        >
          <Text.BodyText4 bold color={merchant?.primary_color || undefined}>
            {t('list.collect', {
              factor: formatFactor(pointsMultiplier.factor),
            })}
          </Text.BodyText4>
          <img
            className={`ml-xxs ${css({ marginTop: '-2px' })}`}
            src={pointsExperience.currency_primary_icon.default}
            alt="currency"
            width={16}
          />
        </div>
      </div>
      <Text.BodyText3 text-nowrap>{dateRange()}</Text.BodyText3>
    </div>
  )
}

export default PreviewBadge
