import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import AccordionButton from '../Bar/AccordionButton'
import MenuItem from '../Bar/MenuItem'
import CampaignsContent from './Content'

const t = buildTranslate('nav.campaigns_menu')

type Props = {
  isLeftNav?: boolean
  onClick?: () => void
  paths: string[]
}

const Campaigns = (props: Props) => {
  const { isLeftNav = false, onClick = () => {}, paths } = props
  const ability = useAbility()

  const menuDisabled =
    ability.cannot('manage', 'TargetedCampaign') &&
    ability.cannot('manage', 'AutomatedCampaign') &&
    ability.cannot('manage', 'CampaignReports') &&
    ability.cannot('manage', 'MessageCampaign') &&
    ability.cannot('manage', 'CampaignPerformanceReport') &&
    ability.cannot('manage', 'AllCampaigns')

  const Parent = isLeftNav ? AccordionButton : MenuItem

  return (
    <Parent disabled={menuDisabled} paths={paths} buttonContents={t('button')}>
      <CampaignsContent isLeftNav={isLeftNav} onClick={onClick} />
    </Parent>
  )
}

export default Campaigns
