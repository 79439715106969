import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { getTargetSegments } from 'actions/targetSegments'
import Counter from 'components/Counter'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { selectTargetSegmentsByMerchant } from 'selectors/targetSegment'
import { numberFormatter } from 'utilities/formatters'

type PropsT = {
  campaign: Campaign
}

const t = buildTranslate('thanx_campaigns.customAutomated.audience')

const Audience: React.FC<PropsT> = props => {
  const { campaign } = props

  const targetSegments = useSelector(selectTargetSegmentsByMerchant)
  let options: {
    label: string | React.ReactElement
    value: number | null
  }[] = targetSegments.map(segment => ({
    label: segment.name,
    value: segment.id,
  }))

  options.unshift({
    label: <div className="grey-70">{t('select.no_segment')}</div>,
    value: null,
  })

  const dispatch = useDispatch()

  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )
  const userCount = userCountMetric?.data?.member_count
  const hasValidUserCount = userCount !== undefined && !isNaN(userCount)

  useEffect(() => {
    dispatch(getTargetSegments())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCampaignMetricByType(campaign.id, 'user_count'))
  }, [dispatch, campaign.id, campaign.config_target_segment_id])

  const currentSegment = targetSegments.find(
    segment => segment.id === campaign.config_target_segment_id
  )

  return (
    <div className="container padding-top-huge">
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div className="d-flex flex-space-between mb-xl">
        <div>
          <Text.Header2 color="grey90">{t('title')}</Text.Header2>
          <Text.BodyText3 color="grey70">{t('subtitle')}</Text.BodyText3>
        </div>
        <Switch condition={campaign.type === 'custom' && hasValidUserCount}>
          <Counter
            className="d-block pt-m"
            primaryValue={numberFormatter(userCount)}
            name={t('customers')}
            isLoading={userCountMetric?.isLoading}
            isErrored={userCountMetric?.isErrored}
          />
        </Switch>
      </div>

      <FormsySelect
        // @ts-ignore
        name="config_target_segment_id"
        label={t('select.title')}
        placeholder={t('select.no_segment')}
        options={options}
        className="mb-xl"
        value={campaign.config_target_segment_id}
        overrides={{
          Popover: {
            props: {
              overrides: {
                Inner: {
                  style: () => ({
                    maxHeight: '50px',
                  }),
                },
              },
            },
          },
        }}
      />

      {!!campaign.config_target_segment_id && (
        <>
          <Text.SmallCaps3 color="grey90" tag="p">
            {t('description.title')}
          </Text.SmallCaps3>
          <Text.BodyText4 color="grey70">
            {currentSegment?.description}
          </Text.BodyText4>
        </>
      )}
    </div>
  )
}

export default Audience
