import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'

import { Role } from 'api/merchantUsers'
import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('thanx_campaigns.performance')

const CampaignPerformanceReport = () => {
  const merchant = useCurrentMerchant()
  const merchantUser = useCurrentMerchantUser()
  const [css] = useStyletron()

  const userIsAdmin =
    merchant && merchantUser
      ? merchantUser.roles[merchant.id].includes(Role.admin)
      : false

  const path = 'dashboards/110'
  const height = 1330

  if (!merchant || !merchantUser) return null

  return (
    <div className="w-100 h-100 pt-m pb-xxl px-0 white-bkg border-bottom-1 grey-20-border">
      <Container className="pt-l pb-xl px-0">
        <div className="d-flex justify-content-between">
          <Text.Header2 className="m-0 mr-xl">{t('pageTitle')}</Text.Header2>
        </div>
      </Container>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className="pb-l grey-05-bkg">
        <div className="container pl-0 pr-0">
          <Text.Header3 className="mt-l mb-xl" tag="div">
            {t('emailEngagement.title')}
          </Text.Header3>
          <Container
            className={`px-0 ${css({ minHeight: `${height + 300}px` })}`}
          >
            <Switch
              condition={userIsAdmin}
              fallback={
                <Text.BodyText3 className="mb-xl">
                  {t('emailEngagement.permissionMessage')}
                </Text.BodyText3>
              }
            >
              <LookerIframe
                path={path}
                merchant={merchant}
                height={`${height}px`}
              />
              <LookerFooter className="my-xl" />
            </Switch>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default CampaignPerformanceReport
