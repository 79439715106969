import { Switch } from '@thanx/uikit/switch'
import NoSearchResultsFound from 'components/NoSearchResultsFound'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import { Fields as Campaign } from 'models/Campaign'
import Table from './Table'

interface CampaignSearchResultsProps {
  campaigns: Campaign[]
  isLoading: boolean
  pagination: {
    currentPage: number
    onChangePage: (page: number) => void
    numPages: number
  }
}

const spinner = (
  <Spinner
    isLoading={true}
    size="4x"
    className="d-flex justify-content-center pt-xl"
  />
)

const CampaignSearchResults = ({
  campaigns,
  isLoading,
  pagination,
}: CampaignSearchResultsProps) => (
  <Switch condition={!isLoading} fallback={spinner}>
    <div className="table-responsive margin-bottom-huge padding-bottom-huge">
      <Switch
        condition={campaigns.length > 0}
        fallback={<NoSearchResultsFound />}
      >
        <Table
          campaigns={campaigns}
          columns={['name', 'date', 'createdBy', 'status', 'reward', 'actions']}
        />

        <div className={'d-flex justify-content-center margin-top-huge'}>
          <Pagination
            currentPage={pagination.currentPage}
            onChangePage={pagination.onChangePage}
            numPages={pagination.numPages}
          />
        </div>
      </Switch>
    </div>
  </Switch>
)

export default CampaignSearchResults
