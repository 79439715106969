import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import Builder from './Builder'
import Creator from './Creator'
import Opportunity from './Landing/Opportunity'
import NewLanding from './Landing/Overview'
import EditEmail from './Messaging/EditEmail'
import EditPush from './Messaging/EditPush'
import EditSms from './Messaging/EditSms'
import Performance from './Performance'
import Report from './Report'
import ReportV3 from './Report/v3'

const CampaignCenter: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        redirect="/"
        resource="AllCampaigns"
        exact
        path="/thanx_campaigns"
        component={NewLanding}
      />
      <ProtectedRoute
        accessType="access"
        redirect="/thanx_campaigns"
        resource="CampaignPerformanceReport"
        exact
        path="/thanx_campaigns/performance"
        component={Performance}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="Opportunity"
        exact
        path="/thanx_campaigns/:category/:type/opportunity"
        component={Opportunity}
      />
      <Route
        exact
        path="/thanx_campaigns/:category/:type"
        component={Creator}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="TargetedCampaign"
        exact
        path="/thanx_campaigns/:id/push/:variantId"
        component={EditPush}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="TargetedCampaign"
        exact
        path="/thanx_campaigns/:id/sms/:variantId"
        component={EditSms}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="TargetedCampaign"
        exact
        path="/thanx_campaigns/:id/email/:variantId"
        component={EditEmail}
      />
      <Route
        exact
        path="/thanx_campaigns/:category/:type/:id"
        component={Builder}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="CampaignReports"
        exact
        path="/thanx_campaigns/:category/:type/:id/report"
        component={Report}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="CampaignReports"
        exact
        path="/thanx_campaigns/:category/:type/:id/report/v3"
        component={ReportV3}
      />
      <Route
        exact
        path="/thanx_campaigns/:category/:type/:id/report/v2"
        render={() => <Redirect to="/thanx_campaigns" />}
      />
    </Switch>
  )
}

export default CampaignCenter
