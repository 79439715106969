export default {
  location_list: {
    details_modal: {
      address: 'Address',
      ordering_url: 'Ordering URL',
      phone_number: 'Phone',
      pos: 'POS',
      timezone: 'Timezone',
    },
    phone: {
      edit_modal: {
        address: 'Address',
        title: 'Update phone number',
        phone: 'Phone',
        submit: 'Save phone number',
        success: 'Success! The location phone number was saved.',
      },
    },
    list_title: 'Location list',
    page_title: 'Locations | Thanx',
    search_placeholder: 'Search locations by name, address or phone',
    table: {
      location: 'Location',
      phone: 'Phone',
      view_details: 'View Details',
      edit: 'Edit',
    },
    title: 'Locations',
  },
}
