import { matchPath } from 'react-router-dom'

const url = require('url')

export default {
  queryValue: queryKey => {
    return url.parse(document.URL, true).query[queryKey]
  },
  matchPath: (path, exact, strict = false) =>
    matchPath(url.parse(document.URL).pathname, {
      path: path,
      exact: exact,
      strict: strict,
    }),
  cookieDomain: () => {
    let domain: string | string[] = window.location.hostname
    if (
      (domain.includes('localhost') ||
        domain.includes('ngrok') ||
        domain.includes('test')) &&
      !process.env.REACT_APP_MERCHANT_ROOT_URL
    ) {
      return null
    }
    domain = domain.replace(/(https?:\/\/)?(dashboard.)?/i, '')
    domain = domain.split('.')
    domain = `.${domain.slice(domain.length - 2).join('.')}`
    return domain.indexOf('/') !== -1 ? domain.split('/')[0] : domain
  },
  formatParams: (params = {}) => {
    return Object.entries(params)
      .map(([key, val]) => `${key}=${val}`)
      .join('&')
  },
  parseUrlParams: (url: string) => {
    return url
      .substring(url.indexOf('?') + 1)
      .split('&')
      .reduce(
        (memo, param) => ({
          ...memo,
          [param.split('=')[0]]: param.split('=')[1],
        }),
        {}
      )
  },
}

export const productFeedbackUrl = 'https://airtable.com/shr2Zdft5lp6oBkBp'
export const rootUrl = '/'
export const baseHelpUrl =
  'https://thanx-merchant-support.zendesk.com/hc/en-us/'
export const helpUrl = `${baseHelpUrl}articles/`
export const automatedCampaignHelpUrl = `${helpUrl}17663695196695-Automated-Campaign-Report`
export const lifecycleMarketing101Url = `${helpUrl}17663659592855-Lifecycle-Marketing-101-Digital-Strategy`
export const lifecycleChartHelpUrl = `${helpUrl}17663660301463-About-the-Activation-Funnel-Report`
export const lifecycleThirdPurchaseUrl = `${helpUrl}17663660585367-Lifecycle-Marketing-102-Customer-Activation`
export const lifecycleBenchmarksHelpUrl = `${helpUrl}17663674989847-Customer-Activation-Benchmarks-by-Industry`
export const lifecycleCampaignHelpUrl = `${helpUrl}17663660585367-Lifecycle-Marketing-102-Customer-Activation`
export const abTestingHelpUrl = `${helpUrl}17663651955223-About-A-B-Testing-and-Control-Groups`
export const abOneTimeUrl = `${helpUrl}17663651630231-How-to-A-B-test-a-One-Time-Campaign`
export const conversionHelpUrl = `${helpUrl}17663660301463-About-the-Activation-Funnel-Report`
export const conversionOverTimeHelpUrl = `${helpUrl}17663646731415-How-to-interpret-customer-activation-data-over-time`
export const hiddenMenuHelpUrl = `${helpUrl}17663619990935-About-Hidden-Menu`
export const goldenTicketHelpUrl = `${helpUrl}17663632668695-About-Access-Passes`
export const hiddenMenuOloHelpUrl = `${helpUrl}17663605700759-How-to-create-a-Hidden-Menu-that-can-be-redeemed-online-Thanx-Olo`
export const bonusPointsHelpUrl = `${helpUrl}17663508431767-About-Bonus-Points`
export const introToPointsUrl = `${helpUrl}17663478272151-Intro-to-Points`
export const restrictionsHelpUrl = `${helpUrl}17663705981719-About-Reward-Restrictions`
export const dccHelpUrl = `${helpUrl}17663577841559-How-to-install-a-Fishbowl-Personica-Promotions-Manager-module-on-your-POS`
export const customImageUrl = `${helpUrl}17663551913623-How-to-display-a-reward-with-a-custom-image`
export const customImageVideoUrl = `${helpUrl}17663551913623-How-to-display-a-reward-with-a-custom-image#h_80bc259e32`
export const customImageManualUrl = `${helpUrl}17663590318103-How-to-use-a-custom-image-for-manual-redemption`
export const customImageManualVideoUrl = `${helpUrl}17663590318103-How-to-use-a-custom-image-for-manual-redemption#h_eacca8b64b`
export const customImageHiddenMenuUrl = `${helpUrl}17663557725591-How-to-create-a-custom-category-image-for-a-Hidden-Menu-reward#h_9fe5541f5d`
export const customImageHiddenMenuVideoUrl = `${helpUrl}17663557725591-How-to-create-a-custom-category-image-for-a-Hidden-Menu-reward#h_21b5b9ea7c`
export const mx4UpdatesUrl = `${helpUrl}17663455368471-Merchant-Experience-2-0`
export const captureRateReportUrl = `${helpUrl}17663474769687-Revenue-Capture-Rate-Report`
export const captureRateImportUrl = `${helpUrl}17663474769687-Revenue-Capture-Rate-Report#h_f7df43c164`
export const orderingManagement = `${helpUrl}17663441088535-How-to-manage-errors-in-your-Ordering-Experience-Toast`
export const smartLinksUrl = `${helpUrl}27605309798423-Smart-Links`
export const emojisUrl = `${helpUrl}17663412790295-Emojis`
export const activationRateUrl = `${helpUrl}17663424771991-Intro-to-Customer-Activation`
export const retentionRateUrl = `${helpUrl}17663397715095-Intro-to-Customer-Retention`
export const effectiveDiscountRateUrl = `${helpUrl}17663370703639-Intro-to-Effective-Discount-Rate`
export const effectiveDiscountRateTableUrl = `${helpUrl}17663394406679-Effective-Discount-Rate-Report`
export const allPurchasesUrl = `${helpUrl}17663376062359-Purchases-Report`
export const mtmWebinarUrl = `${helpUrl}7118099-q1-2023-customer-webinar` // <- Not found in Intercom
export const aboutRewardDiscountsAndCosts = `${helpUrl}17663440501271-About-reward-discounts-and-costs-COGS`
export const enableDisableLocations = `${helpUrl}17663418565399-How-to-temporarily-disable-locations-in-your-ordering-experiences-Toast`
export const copyEmailUrl = `${helpUrl}17663375752855-Copy-content-from-another-email`
export const cmsSignupWhereCustomersSeeUrl = `${helpUrl}17663471912215-Sign-up-Language`
export const cmsBrandedAppConfigUrl = `${helpUrl}17663531502743-How-logos-colors-and-cover-image-are-applied-in-branded-apps`
export const customizableAppsUrl = `${helpUrl}21256439661975-Customizable-app-home-page`
export const animatedGifUrl = `${helpUrl}28453478570903-Animated-GIFs-in-the-customer-experience`
export const bigBookUrl =
  'https://www.thanx.com/resources/guides/restaurant-loyalty-campaigns/?g'
export const mtmEffectivenessUrl =
  'https://drive.google.com/file/d/1bj-zJzrckLo5Ph_igAFiE15IYMt73hly/view'
export const cardOrderConversionTargetingUrl = `${helpUrl}17663351872663-Cart-Abandonment`
export const integrationsCenterUrls = {
  posInstoreSkuData: {
    toast: `${helpUrl}17663374766231-Integrations#h_261093add7`,
    revel: `${helpUrl}17663374766231-Integrations#h_261093add7`,
    olo_omnivore: `${helpUrl}17663374766231-Integrations#h_aed3e1a26c`,
  },
  posInstoreOfferManagement: {
    thanx: `${helpUrl}17663374766231-Integrations#h_d081573102`,
    toast: `${helpUrl}17663374766231-Integrations#h_261093add7`,
    fishbowl: `${helpUrl}17663374766231-Integrations#h_960acdcc0c`,
  },
  digital_order_management: {
    olo: `${helpUrl}17663374766231-Integrations#h_c8b060d154`,
    toast: `${helpUrl}17663374766231-Integrations#h_c8b060d154`,
    open_tender: `${helpUrl}17663374766231-Integrations#h_c8b060d154`,
    deliverect: `${helpUrl}17663374766231-Integrations#h_c8b060d154`,
  },
  delivery: {
    olo_dispatch: `${helpUrl}17663374766231-Integrations#h_25c0e29014`,
    doordash: `${helpUrl}17663374766231-Integrations#h_25c0e29014`,
    vromo: `${helpUrl}17663374766231-Integrations#h_25c0e29014`,
  },
  cdp: {
    olo_gdp: `${helpUrl}17663374766231-Integrations#h_89f50b7f69`,
    bikky: `${helpUrl}17663374766231-Integrations#h_89f50b7f69`,
    delightable: `${helpUrl}17663374766231-Integrations#h_89f50b7f69`,
    klaviyo: `${helpUrl}17663374766231-Integrations#h_89f50b7f69`,
  },
  emailMarketing: {
    thanx: `${helpUrl}17663374766231-Integrations#h_66e638dea2`,
    klaviyo: `${helpUrl}17663374766231-Integrations#h_8223ed6fb1`,
    braze: `${helpUrl}17663374766231-Integrations#h_8223ed6fb1`,
    olo_gdp: `${helpUrl}17663374766231-Integrations#h_8223ed6fb1`,
    fishbowl: `${helpUrl}17663374766231-Integrations#h_8223ed6fb1`,
  },
  smsMarketing: {
    thanx: `${helpUrl}17663374766231-Integrations#h_9cf7806169`,
    klaviyo: `${helpUrl}17663374766231-Integrations#h_29c19fd404`,
  },
  feedback: {
    thanx: `${helpUrl}17663374766231-Integrations#h_3376e42260`,
    tattle: `${helpUrl}17663374766231-Integrations#h_0639198440`,
    ovation: `${helpUrl}17663374766231-Integrations#h_0639198440`,
  },
  storedValue: {
    ansa: `${helpUrl}17663374766231-Integrations#h_d0c37b36f2`,
  },
  help: `${helpUrl}17663374766231-Integrations`,
}
export const grantRewardUrl = `${helpUrl}17663684561303-Rewards-Overview`

export const cmsUrls = {
  bannerAlert: `${helpUrl}17663516333847-Banner-alerts-across-branded-apps-and-web-ordering`,
  brandedHomepage: `${helpUrl}17663512566551-Branded-app-homepage-content-blocks`,
}

export const marketplaceUrls = {
  pointsProgram: `${helpUrl}17663497252247-Configuring-the-Rewards-Marketplace-for-your-Points-program-from-the-Thanx-dashboard`,
}

export const pointsUrls = {
  configurePoints: `${helpUrl}17663499040151-Configuring-your-Points-Program-from-the-Thanx-Dashboard`,
}

// TODO
export const createSlideImageUrl = ''
export const createSlideVideoUrl = ''

export const userSupportUrl =
  'https://help.thanx.com/hc/en-us/articles/20812137318167-Thanx-managed-consumer-support-FAQ'
export const userSupportOptOutUrl = `${userSupportUrl}#h_01HYP7T2RD70YZS66ME9JB3K9N`
export const mailToMerchantSupportUrl =
  'mailto:billing@thanx.com?subject=End%20User%20Support'

export const configureRedemptionMethodUrl =
  'https://help.thanx.com/hc/en-us/articles/17663772532119-Configuring-the-redemption-method-of-a-reward'
export const configureToastLoyaltyIntegrationUrl =
  'https://help.thanx.com/hc/en-us/articles/17663364056215-Thanx-Toast-Loyalty-Integration'
export const configureCouponCodesUrl =
  'https://help.thanx.com/hc/en-us/articles/17663577459223-Coupon-Codes-Generated-by-Thanx'
