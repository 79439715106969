import { Switch } from '@thanx/uikit/switch'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import React, { useRef } from 'react'
import DatePicker from 'react-16-bootstrap-date-picker'
import { ControlLabel, FormGroup, InputGroup } from 'react-bootstrap'
import { textUtils } from 'utilities/textUtils'

type Props = {
  minDate?: string
  maxDate?: string
  disabled?: boolean
  onChange?: (string) => void
  mapValue?: (string) => string
  label?: string
} & PassDownProps<string | null>

const ThanxDatePicker: React.FC<Props> = props => {
  const datePickerRef = useRef<DatePicker | null>(null)

  const handleCalendarIconClick = () => {
    datePickerRef.current?.handleFocus()
  }
  function onChange(value: string) {
    value = props.mapValue ? props.mapValue(value) : value
    props.setValue(value)
    props.onChange?.(value)
  }

  const className =
    props.showError && !props.isPristine ? 'form-group has-error' : 'form-group'
  const errorMessages = props.isPristine ? '' : props.errorMessages
  return (
    <FormGroup
      className={className}
      bsSize="lg"
      controlId={`${props.name}DatePicker`}
    >
      <Switch condition={!!props.label}>
        <ControlLabel>{props.label}</ControlLabel>
      </Switch>
      <div className="control-label cursor-pointer">
        <InputGroup name={props.name} className="padding-top-small datepicker">
          <DatePicker
            ref={datePickerRef}
            value={props.value}
            minDate={props.minDate}
            showClearButton={false}
            onChange={onChange}
            disabled={props.disabled}
          />
          <InputGroup.Addon onClick={handleCalendarIconClick}>
            <i className="fa fa-calendar" />
          </InputGroup.Addon>
        </InputGroup>
      </div>
      <span className="text-danger error-message block">
        {textUtils.initialCapital(
          textUtils.toSentence({
            array: errorMessages as string[],
            punctuation: '',
          })
        )}
      </span>
    </FormGroup>
  )
}

export default withFormsy(ThanxDatePicker)
