import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'

const t = buildTranslate('components.no_search_results_found')

type Props = {
  title?: string
  body?: string
}

const NoSearchResultsFound = ({
  title = t('title'),
  body = t('body'),
}: Props) => (
  <div className="text-center">
    <Text.Header4 color="grey90" bold className="mt-0">
      {title}
    </Text.Header4>
    <Text.BodyText3 color="grey70">{body}</Text.BodyText3>
  </div>
)

export default NoSearchResultsFound
