import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import NavLink from '../Bar/NavLink'

const t = buildTranslate('nav.campaigns_menu')

type Props = {
  isLeftNav?: boolean
  onClick?: () => void
}

const CampaignsContent = (props: Props) => {
  const { isLeftNav = false, onClick = () => {} } = props
  const ability = useAbility()

  return (
    <>
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('all_campaigns')}
        link="/thanx_campaigns"
        disabled={ability.cannot('manage', 'AllCampaigns')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('performance')}
        link="/thanx_campaigns/performance"
        status="new"
        disabled={ability.cannot('manage', 'CampaignPerformanceReport')}
      />
    </>
  )
}

export default CampaignsContent
