import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import HasTest from './HasTest'
import NoTest from './NoTest'

const t = buildTranslate('thanx_campaigns.report.v3.test')

type Props = {
  campaign: Campaign
}
const Test: React.FC<Props> = ({ campaign }) => {
  const { state, variants } = campaign
  const hasVariants = variants?.length > 1 || false
  return (
    <div>
      <Text.Header3 tag="p" className="m-0 mt-s mb-xl">
        {t('title')}
      </Text.Header3>
      {hasVariants ? (
        <HasTest campaign={campaign} variants={variants} />
      ) : (
        <NoTest state={state} />
      )}
    </div>
  )
}

export default Test
