import { useState } from 'react'

import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { phoneNumberFormatter } from 'utilities/formatters'

import Modal from 'components/ModalContainer'
import EditButton from 'scenes/Locations/Landing/DetailsModal/EditButton'
import ItemCol from 'scenes/Locations/Landing/DetailsModal/ItemCol'
import EditPhoneNumberModal from 'scenes/Locations/Landing/EditPhoneNumberModal'

import type { Fields as Location } from 'models/Location'

type Props = {
  isOpen: boolean
  onClose: () => void
  location: Location
}

const t = buildTranslate('locations.location_list')

const DetailsModal = (props: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const merchant = useCurrentMerchant()
  const { isOpen, onClose, location } = props
  const { id, city, street, state, zip, phone, redemption_provider } = location

  return (
    <Modal
      proceedText={buildTranslate('shared_words')('close')}
      isOpen={isOpen}
      onClose={onClose}
      onProceed={onClose}
      hideCancel={true}
      showBottomProceed={true}
      buttonGroupChildren={
        <EditButton setIsEditModalOpen={setIsEditModalOpen} />
      }
    >
      <div className="display-flex flex-space-between">
        <h3 className="mt-0 mb-xs extra-bold">{location.name}</h3>
        <EditButton setIsEditModalOpen={setIsEditModalOpen} />
      </div>
      <div className="mt-xl">
        <ItemCol
          className="mb-4"
          label={t('details_modal.address')}
          value={`${street}, ${city}, ${zip}, ${state}`}
        />
        <ItemCol
          className="mb-4"
          label={t('details_modal.phone_number')}
          value={phone && `1 ${phoneNumberFormatter(phone)}`}
        />
        <ItemCol
          className="mb-4"
          label={t('details_modal.ordering_url')}
          value={`https://order.thanx.com/${merchant?.handle}?location=${id}`}
        />
        {redemption_provider && (
          <ItemCol
            className="mb-4"
            label={t('details_modal.pos')}
            value={redemption_provider.label}
          />
        )}
        <ItemCol
          className="mb-4"
          label={t('details_modal.timezone')}
          value={location.time_zone}
        />
      </div>
      <EditPhoneNumberModal
        isOpen={isEditModalOpen}
        location={location}
        onClose={() => setIsEditModalOpen(false)}
      />
    </Modal>
  )
}

export default DetailsModal
